var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo')],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5 bgColor6",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{staticClass:"left-image",attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Forgot password V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center px-2 p-lg-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"7"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" RESET PASSWORD ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Create a new password. ")]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"a-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-email","state":errors.length > 0 ? false : null,"type":"email","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"a-password"}},[_c('validation-provider',{attrs:{"name":"New Password","vid":"Password","rules":"required|min:8|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-password","state":errors.length > 0 ? false : null,"type":"password","placeholder":"Password"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Confirm New Password","label-for":"ac-password"}},[_c('validation-provider',{attrs:{"name":"Confirm New Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ac-password","state":errors.length > 0 ? false : null,"type":"password","placeholder":"Confirm New Password"},model:{value:(_vm.passValue),callback:function ($$v) {_vm.passValue=$$v},expression:"passValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Verification Code","label-for":"ac-code"}},[_c('validation-provider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ac-code","state":errors.length > 0 ? false : null,"type":"code","placeholder":"Verification Code"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.loading)?_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":""},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"label":"Loading..."}}):_vm._e()],1):_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"size":"16","icon":"CheckCircleIcon"}}),_vm._v(" Submit ")],1)],1)],1)],1)],1),_c('p',{staticClass:"text-center mt-3"},[_c('b-link',{attrs:{"to":{ name: 'auth-login-v2' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Back to login ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }