<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 bgColor6">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            class="left-image"
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="6" class="d-flex align-items-center px-2 p-lg-5">
        <b-col sm="8" md="6" lg="7" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            RESET PASSWORD
          </b-card-title>
          <b-card-text class="mb-2"> Create a new password. </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <!-- Email -->
                <b-col cols="12">
                  <b-form-group label="Email" label-for="a-email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="a-email"
                        v-model="email"
                        :state="errors.length > 0 ? false : null"
                        type="email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- password -->
                <b-col cols="12">
                  <b-form-group label="Password" label-for="a-password">
                    <validation-provider
                      #default="{ errors }"
                      name="New Password"
                      vid="Password"
                      rules="required|min:8|password"
                    >
                      <b-form-input
                        id="a-password"
                        v-model="username"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- confirm password -->
                <b-col cols="12">
                  <b-form-group
                    label="Confirm New Password"
                    label-for="ac-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm New Password"
                      rules="required|confirmed:Password"
                    >
                      <b-form-input
                        id="ac-password"
                        v-model="passValue"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Confirm New Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- reset code -->
                <b-col cols="12">
                  <b-form-group label="Verification Code" label-for="ac-code">
                    <validation-provider
                      #default="{ errors }"
                      name="Code"
                      rules="required"
                    >
                      <b-form-input
                        id="ac-code"
                        v-model="code"
                        :state="errors.length > 0 ? false : null"
                        type="code"
                        placeholder="Verification Code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- reset button -->
                <b-col cols="12">
                  <b-button
                    v-if="loading"
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                    disabled
                  >
                    <b-spinner v-if="loading" label="Loading..." />
                  </b-button>
                  <b-button
                    v-else
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    <feather-icon
                      size="16"
                      class="mr-1"
                      icon="CheckCircleIcon"
                    />
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <p class="text-center mt-3">
            <b-link :to="{ name: 'auth-login-v2' }">
              <feather-icon icon="ArrowLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>
  
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
} from "bootstrap-vue";
import { required, email, min, confirmed } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      passValue: "",
      username: "",
      email: "",
      code: "",
      required,
      min,
      confirmed,
      loading: false,
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
    };
  },
  computed: {
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
      //   return this.sideImg
      // }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        // PPpp!!22
        if (success) {
          this.loading = true;
          const data = {
            email: this.email,
            password: this.username,
            c_password: this.passValue,
            verification_code: this.code,
          };
          axiosIns
            .post(`/reset-password`, data)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              setTimeout(() => {
                this.$router.replace("/");
              }, 2000);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
        // store
        //     .dispatch("app-user/updateUser", this.userData)
        //     .then((response) => {
        //         this.$swal({
        //             text: response.data.message,
        //             icon: "success",
        //             customClass: {
        //                 confirmButton: "btn btn-primary",
        //             },
        //             showClass: {
        //                 popup: "animate__animated animate__bounceIn",
        //             },
        //             buttonsStyling: false,
        //         });
        //         this.$emit("refetch-data");
        //         setTimeout(() => {
        //             this.setLicense_type()
        //         }, 500)

        //     })
        //     .catch((error) => {
        //         this.$swal({
        //             text: JSON.stringify(error.response.data.message),
        //             icon: "error",
        //             customClass: {
        //                 confirmButton: "btn btn-primary",
        //             },
        //             showClass: {
        //                 popup: "animate__animated animate__bounceIn",
        //             },
        //             buttonsStyling: false,
        //         });
        //     });
      });
    },
  },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
  